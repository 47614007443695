import Layout from "components/layout/Layout";
import { PageHeader } from "components/PageHeader/PageHeader";
import { SEO } from "components/SEO";
import React from "react";

const Impressum: React.FC = () => {
  return (
    <Layout>
      <SEO title="Impressum" />

      <PageHeader title="Impressum" />
    </Layout>
  );
};

export default Impressum;
